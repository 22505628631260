<template>
  <BaseLayout title="自动建仓" subtitle="设置好选币条件 机器人帮你建仓" :bg="background" fg="#060">

    <div class="info">

      <div class="intro">
        <div class="what">拥抱人工智能</div>
        运用最新的人工智能算法、基于大数据分析，根据每个品种的买卖深度对比、搜索系数和K线震荡指数，实时云计算得出每个策略组的买入条件和买入间隔。机器人实时监测报价，根据策略自动建仓买进。
        <div class="todo">
          <van-button plain icon="play-circle" icon-position="right" color="#66F" size="small" to="/intro">详细介绍</van-button>
          &nbsp;
          <van-button plain icon="share" icon-position="right" color="#F66" size="small" to="/my/exchange">我要使用</van-button>
        </div>
      </div>

      <div class="list">
        <van-divider class="title" content-position="left">最近的建仓记录</van-divider>
        <div class="item" v-for="(item,index) in state.list" :key="index">
          <div class="dt"><van-tag color="#CCC">{{item.dt}}</van-tag></div>
          <van-cell class="coin" :border="false" icon-prefix="icon-symbol" :icon="item.symbol.replace(/usdt$/, '').toLowerCase()" :title="item.symbol.replace(/usdt$/, '').toUpperCase() + ' / USDT'" :label="'数量：' + item.num + '，单价：' + item.price"/>
        </div>
      </div>

    </div>

  </BaseLayout>
</template>

<style scoped>
.info {
  min-height: 600px;
  padding: 10px;
  background: #F9F9F9;
  border-radius: 20px 20px 0 0;
}


.intro {
  margin: 0px;
  padding: 10px;
  background: #FFF;
  border: solid 1px #CC6;
  border-radius: 10px;
  color: #333;
  font-size: 14px;
  line-height: 24px;
  display: block;
}
.what {
  margin-bottom: 5px;
  padding: 3px 0px 3px 5px;
  background: linear-gradient(45deg, #66F, #F66 60%, #FF9);
  background: linear-gradient(45deg, #6FF, #FFF 80%, #FFF);
  color: #339;
}
.todo {
  width: 100%;
  margin-top: 10px;
  text-align: right;
  display: inline-block;
}


.list {
  margin: 0px;
}
.title {
  margin: 30px 5px;
  color: #999;
  border-color: #999;
  font-size: 14px;
}
.item {
  position: relative;
  margin-top: 50px;
  padding: 0px 10px 10px 10px;
  background: #FFF;
  border: solid 1px #EEE;
  border-radius: 10px;
  font-size: 12px;
}
.dt {
  position: relative;
  top: -25px;
  text-align:center;
}
.coin {
  margin: 0 0 10px 0;
  height: 60px;
  background: transparent;
  color: #333;
  font-size: 18px;
}
</style>

<script>
import { reactive } from 'vue'
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  components: {BaseLayout},

  setup(){
    const state = reactive({
      list: []
    });
    return {
      state,
    };
  },

  created() {
    this.$http.get("/exchange/recent?type=1&limit=50").then((data) => this.state.list = data);
  },

  computed: {
    background() {
      return "url('" + require('../assets/bg2.jpg') + "') top right";
    },
  }
}
</script>
